<template>
    <section class="container-fluid dash-search-box">
            <div id="snackbar"></div>
            <div style="    padding: 4%;">
                <h1 style="color: white;font-weight: bold;line-height: 1.35;">Support</h1>
                    <p style="color: #c7c7c7;font-size:1em;line-height: 1.41176471;font-weight: bold;">For Operations related queries (like DCP and KDM), write to 
                        <b>operations@gogocinemas.com</b>
                        <br><br>For Accounts related queries write to 
                        <b>accounts@gogocinemas.com</b>
                        <br><br>For Content related queries write to 
                        <b>content@gogocinemas.com</b><br>
                    </p>
                                                
            </div>
        </section>
</template>

<script>
export default {};
</script>

<style>
</style>
